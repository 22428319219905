import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Value } from '../../../../../dataset/Value';
import { Achievement } from '../../../../../dataset/Achievement';
import { UserContextService } from '../../../../../context/user-context/user-context.service';
import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../interfaces/IDialog';
import { AchievementsContextService } from '../../../../../context/achievements-context/achievements-context.service';
import { AchievementsApiService } from '../../../../../api/achievements-api/achievements-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-add-achievement-modal',
  templateUrl: './add-achievement-modal.component.html',
  styleUrls: ['./add-achievement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAchievementModalComponent extends Dialog implements IDialog, OnInit, OnDestroy {
  @ViewChild('fileInput') fileInputElementRef: ElementRef;

  achievementTypes: Value<number>[] | null;
  achievementType?: Value<number>;
  date: Date = new Date();
  file?: File;

  min?: Date = this.userContext.get()?.created_at;
  max = new Date();

  preview?: string;
  loading = false;

  get achievement(): Achievement | undefined {
    if (this.data && this.data?.achievement) {
      return this.data?.achievement as Achievement;
    }

    return undefined;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private achievementsApiService: AchievementsApiService,
    private achievementsContext: AchievementsContextService,
    private userContext: UserContextService
  ) {
    super();
  }

  ngOnInit(): void {
    this.achievementsContext
      .getTypes$()
      .pipe(untilDestroyed(this))
      .subscribe(async types => {
        this.achievementTypes = types;

        if (this.achievement && types && types.length) {
          this.achievementType = types.find(t => t.value === this.achievement?.type?.id);

          this.date = dayjs(this.achievement.date_of_achievement, '"YYYYMMDD[T]HHmmss"').toDate();

          this.preview = this.achievement.image_url
            ? `url(${this.achievement.image_url})`
            : undefined;
        }
        this.cd.detectChanges();
      });

    if (!this.achievementTypes?.length) {
      this.achievementsApiService
        .getAchievementsTypes()
        .pipe(
          tap(res => this.achievementsContext.nextTypes(res)),
          untilDestroyed(this)
        )
        .subscribe(res => {
          if (this.achievement) {
            this.achievementType = res.find(t => t.value === this.achievement?.type?.id);
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  fileChangeListener($event: Event): void {
    this.file = ($event?.target as HTMLInputElement)?.files?.[0];
    const fileReader = new FileReader();

    fileReader.onloadend = loadEvent => {
      this.preview = `url(${loadEvent.target?.result})`;
      this.cd.detectChanges();
    };

    if (this.file) {
      fileReader.readAsDataURL(this.file);
      this.fileInputElementRef.nativeElement.value = '';
    }
  }

  closeModal(): void {
    this.resolve();
  }

  removeImage(): void {
    this.preview = undefined;
    this.file = undefined;
  }

  report(): void {
    this.loading = true;
    let operation: Observable<Achievement | undefined>;

    if (this.achievement) {
      operation = this.achievementsApiService.getMedia(this.achievement.id, this.file).pipe(
        switchMap(() => {
          if (this.achievement?.id) {
            this.achievementsApiService.edit(this.achievement?.id, {
              type: this.achievementType?.value,
              date: this.date,
            });
          }

          return of(undefined);
        })
      );
    } else {
      operation = this.achievementsApiService.create(
        {
          type: this.achievementType?.value,
          date: this.date,
        },
        this.file
      );
    }

    operation
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (achievement?: Achievement) => this.resolve(achievement),
        error: err => alert(err.message),
      });
  }

  protected readonly ChangeDetectionStrategy = ChangeDetectionStrategy;
}
