<app-dialog-container
  [state]="state"
  (close)="closeModal()">
  <app-loading *ngIf="loading" />

  <div
    fxLayout="column"
    fxLayoutGap="30px">
    <h2 class="st-h2 text-center">{{ 'Report an achievement' | translate }}</h2>

    <div
      fxLayout="column"
      fxLayoutGap="20px">
      <div
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayout.lt-sm="column">
        <st-form-field
          fxFlex
          [label]="'Choose an achievement' | translate">
          <st-dropdown
            *ngIf="achievementTypes"
            [items]="$any(achievementTypes)"
            [(ngModel)]="achievementType"
            [placeholder]="'Choose an achievement' | translate" />
        </st-form-field>

        <app-date-input
          fxFlex
          [(date)]="date"
          [up]="false"
          [new]="true"
          [min]="min"
          [max]="max"
          [label]="'Pick a date' | translate"
          [placeholder]="'Choose a date' | translate" />
      </div>

      <div
        fxLayout="row"
        *ngIf="preview">
        <div
          class="image-preview"
          fxFlex>
          <div
            class="preview"
            [style.backgroundImage]="preview">
          </div>

          <button
            class="remove"
            (click)="removeImage()">
            <svg
              class="st-stroke-black"
              width="16"
              height="16">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>

        <div fxFlex></div>
      </div>

      <input
        type="file"
        style="display: none"
        #fileInput
        (change)="fileChangeListener($event)" />

      <button
        class="st-btn"
        (click)="fileInput.click()">
        {{ 'add a photo' | translate }}
      </button>

      <button
        class="st-btn st-btn-dark"
        [disabled]="!date || !achievementType || loading"
        (click)="report()">
        {{ (achievement ? 'save' : 'done') | translate }}
      </button>
    </div>
  </div>
</app-dialog-container>
