import { Inject, Injectable } from '@angular/core';
import { Api } from '../extends/api';
import { IApi } from '../../core/interrfaces/IApi';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Achievement } from '../../dataset/Achievement';
import { map } from 'rxjs/operators';
import { AchievementType } from '../../dataset/AchievementType';
import { Value } from '../../dataset/Value';
import { COMMON_BASE_URL } from '../../core/tokens/common-base-url.token';
import { fixTZ } from '../../services/timezone/timezone.fix';
import dayjs from 'dayjs';

export interface AchievementParams {
  type?: number;
  date?: Date;
}

@Injectable()
export class AchievementsApiService extends Api implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }

  getAchievementsTypes(): Observable<Value<number>[]> {
    return this.http.get<AchievementType[]>(this.buildBaseUrl('api/achievements/types.json')).pipe(
      map(types =>
        types.map(t => ({
          label: t.name,
          value: t.id,
        }))
      )
    );
  }

  setFormDataParams(params: AchievementParams, file?: File): FormData {
    const formData = new FormData();

    formData.append('type', String(params.type));
    formData.append('date_of_achievement', dayjs(params.date).format('YYYYMMDD[T]HHmmss'));

    if (file) {
      formData.append('media[binaryContent]', file);
    }

    return formData;
  }

  edit(id: number, params: AchievementParams): Observable<Achievement> {
    const formData = this.setFormDataParams(params);

    return this.http.patch<Achievement>(this.buildBaseUrl(`api/achievements/${id}.json`), formData);
  }

  create(params: AchievementParams, file?: File): Observable<Achievement> {
    const formData = this.setFormDataParams(params, file);

    return this.http.post<Achievement>(this.buildBaseUrl('api/achievements.json'), formData);
  }

  getMedia(id: number, file?: File): Observable<Achievement[]> {
    const formData = new FormData();
    if (file) {
      formData.append('media[binaryContent]', file);
    }

    return this.http.post<Achievement[]>(
      this.buildBaseUrl(`api/achievements/${id}/media.json`),
      formData
    );
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(this.buildBaseUrl(`api/achievements/${id}.json`));
  }

  retrieve(): Observable<Achievement[]> {
    return this.http.get<Achievement[]>(this.buildBaseUrl('api/achievements.json?direction=desc'), {
      params: this.params({
        time_zone: fixTZ(dayjs.tz.guess()),
      }),
    });
  }
}
