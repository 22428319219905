import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Achievement } from '../../dataset/Achievement';
import { IContext } from '../interfaces/IContext';
import { Value } from '../../dataset/Value';

@Injectable()
export class AchievementsContextService implements IContext<Achievement[] | null> {
  private achievements = new BehaviorSubject<Achievement[] | null>(null);
  private types = new BehaviorSubject<Value<number>[] | null>(null);

  clear(): void {
    this.achievements.next(null);
  }

  getTypes$(): Observable<Value<number>[] | null> {
    return this.types.asObservable();
  }

  nextTypes(value: Value<number>[]): void {
    this.types.next(value);
  }

  get(): Achievement[] | null {
    return this.achievements.getValue();
  }

  get$(): Observable<Achievement[] | null> {
    return this.achievements.asObservable();
  }

  next(value: Achievement[] | null): void {
    this.achievements.next(value);
  }
}
